@import 'common/styles/main.scss';

.App {
	font-family: Roboto;
	min-height: 100vh;
}

body {
	.MuiPaper-root {
		.MuiList-root {
			.MuiMenuItem-root {
				&.Mui-selected {
					background-color: rgba(0, 93, 144, 0.35);
				}
			}
		}
	}
}
