@import 'common/styles/variables';

.Header {
	&-container {
		&.MuiContainer-root {
			display: flex;
			gap: $spaceBase / 2;
			align-items: center;
		}

		&-icon {
			margin-left: $spaceBase * -1 + 2;
		}
	}
}
