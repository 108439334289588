@import 'common/styles/variables';

.ProjectFilters {
	margin-bottom: $spaceBase;

	&-form {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: $spaceBase;

		&-actions {
			display: flex;
			justify-content: flex-start;
			gap: $spaceBase;
		}
	}
}

.Project-OccupationRate {
	margin: 0 0 $spaceBase;
}
