@import 'common/styles/variables';

.CategoryDialog .CategoryForm {
	&-form {
		&-rates {
			margin-top: $spaceBase;

			&-item {
				margin-bottom: $spaceBase / 2;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
