@import './variables';
@import './classes';

body {
	color: $textColor;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

	a {
		text-decoration: none;
		color: $mainColor;
		font-weight: 500;
	}

	img {
		max-width: 100%;
	}

	.MuiTableContainer-root.MuiBox-root {
		border: 1px solid $gray5;
		border-radius: $radiusBase;
	}

	.MuiTableHead-root {
		.MuiTableRow-root {
			background: $gray2;

			.MuiTableCell-root {
				padding: 6px 8px;
			}

			&:last-child th {
				border-bottom: 1px solid $gray5;
			}
		}
	}

	.MuiTableBody-root {
		.MuiTableRow-root {
			&:last-child td,
			&:last-child th {
				border: 0;
			}
		}
	}

	.MuiSlider-root {
		padding: 10px 0;
		margin-bottom: $spaceBase;

		.MuiSlider-markLabel {
			line-height: 1;
			top: 24px;
		}
	}
}
