@import 'common/styles/variables';

.GuestLayout {
	min-height: calc(100vh - 32px);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spaceBase;

	&-container {
		border-radius: $radiusBase;
		padding: $spaceBase * 1.5;
		background: white;
		width: 100%;

		@media screen and (min-width: $xs) {
			padding: $spaceBase * 2;
		}

		@media screen and (min-width: $sm) {
			max-width: $xs;
			border: 1px solid $gray4;
		}

		&-logo,
		&-title {
			text-align: center;
		}

		&-title {
			margin-top: $spaceBase * 1.5;
		}

		&-description {
			margin: 0 auto;
			margin-top: $spaceBase / 2;
			color: $secondaryTextColor;
			text-align: center;
		}

		&-content {
			margin-top: $spaceBase;
		}

		&-extra {
			margin-top: $spaceBase;
			padding-top: $spaceBase;
			border-top: 1px solid $gray4;

			&-item {
				margin-bottom: $spaceBase;
				text-align: center;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
