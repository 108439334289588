@import 'common/styles/variables';

.UserDetails {
	&-info,
	&-about,
	&-ratings {
		display: flex;
		gap: $spaceBase;
		flex-wrap: wrap;
	}

	&-info {
		.LabeledDescription {
			flex: 0 1 266px;
		}
	}

	&-about {
		.LabeledDescription {
			flex: 1;
		}
	}

	&-ratings {
		gap: $spaceBase * 1.5;
	}
}
