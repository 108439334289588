.ClientAccounting {
  width: 100%;
  max-width: 900px;
}

.AccountingFilters {
  margin-bottom: 16px;
  
  &-form {
    display: flex;
    gap: 16px;
  }
}