@import 'common/styles/variables';

.TitledBox {
	border: 1px solid $gray6;
	border-radius: $radiusBase;
	padding: $spaceBase;
	position: relative;
	margin-bottom: $spaceBase * 1.5;
	overflow: visible;

	&:last-child {
		margin-bottom: 0;
	}

	&-title {
		&.MuiTypography-root {
			padding: $spaceBase / 2;
			background: white;
			margin-top: $spaceBase * -2.25;
			width: fit-content;
			color: $gray7;
			font-weight: 500;
		}
	}
}
