@import 'common/styles/variables';

.AssignmentDialog .AssignmentForm {
	&-form {
		&-rates {
			margin-top: $spaceBase;

			&-item {
				margin-bottom: $spaceBase / 2;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.AssignmentDialog .AssignmentForm .CurrentAssignments {
	&-wrapper {
		background-color: $gray1;
		border-radius: $spaceBase * 0.25;
		border: 1px solid $gray4;
		padding: $spaceBase * 0.75;
	}

	&-list {
		margin-top: $spaceBase * 0.5;
		display: flex;
		flex-direction: column;
		gap: $spaceBase * 0.5;
	}
}
