@import 'common/styles/variables';

.Footer {
	&-container {
		&.MuiContainer-root {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: $spaceBase / 2;
			line-height: 1;

			@media screen and (min-width: $sm) {
				flex-direction: unset;
				align-items: center;
				justify-content: space-between;
			}
		}

		&-logo {
			a {
				display: inherit;
				height: auto;
			}
		}
	}
}
