$sides: (
	t: top,
	r: right,
	b: bottom,
	l: left,
);

$cssProperties: (
	m: margin,
	p: padding,
);

$axis: x, y;
$spaces: 0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128;
$sizes: 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72;

@each $space in $spaces {
	@each $prop, $cssProperty in $cssProperties {
		.#{$prop}-#{$space} {
			#{$cssProperty}: #{$space}px;

			/*
      .m-0 {
        margin: 0px;
      }
      */
		}

		@each $s, $side in $sides {
			.#{$prop}#{$s}-#{$space} {
				#{$cssProperty}-#{$side}: #{$space}px;
			}

			/*
        .mt-0 {
          margin-top: 0px;
        }
      */
		}

		.#{$prop}x-#{$space} {
			#{$cssProperty}-left: #{$space}px;
			#{$cssProperty}-right: #{$space}px;
		}

		/*
      .mx-0 {
        margin-left: 0px;
        margin-right: 0px;
      }
    */

		.#{$prop}y-#{$space} {
			#{$cssProperty}-top: #{$space}px;
			#{$cssProperty}-bottom: #{$space}px;
		}

		/*
      .my-0 {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    */
	}
}

@each $size in $sizes {
	.fs#{$size} {
		font-size: #{$size}px;
	}

	/*
    .fs12 {
      font-size: 12px;
    }
  */
}
