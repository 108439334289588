@import 'common/styles/variables';

.UserMenu {
	&-list {
		&.MuiList-root {
			padding-top: 0;
		}

		&-name {
			padding: $spaceBase / 2 $spaceBase;
			line-height: 1rem;
			text-align: center;
			background: $gray9;
			color: white;
			display: flex;
			flex-direction: column;
			margin-bottom: $spaceBase / 2;
		}

		&-item {
			&.MuiMenuItem-root {
				padding: 0;

				a {
					padding: $spaceBase / 2 $spaceBase;
					display: flex;
					flex: 1;
					gap: $spaceBase;
					width: 100%;
					color: $gray7;

					&.activeLink {
						color: $mainColor;
						background-color: #eee;
					}

					&:hover {
						color: $mainColor;
					}
				}
			}
		}
	}
}
