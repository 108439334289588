@import 'common/styles/variables';

.MyAverage {
  align-items: center;
  border-radius: $spaceBase * 0.5;
  border: 1px solid $gray4;
  box-shadow: 1px 1px 6px $gray5;
  display: block;
  display: flex;
  flex-direction: column;
  flex: 0 1 200px;
  gap: $spaceBase * 0.5;
  height: 100%;
  padding: $spaceBase;
  position: relative;
  text-align: center;
  transition: 175ms ease all;

  &-text {
    font-size: 14px;
    color: $gray6;
  }

  &-rank {
    font-size: 52px;
    color: $secondaryColor;
  }
}