@import 'common/styles/variables';

.UserFilters {
	margin-bottom: $spaceBase;

	&-form {
		display: flex;
		flex-direction: column;
		gap: $spaceBase;
		align-items: flex-start;

		@media screen and (min-width: $md) {
			flex-direction: row;
		}

		.MuiFormControl-root {
			margin-top: 0;
			@media screen and (min-width: $md) {
				margin-top: 0;
			}
		}

		& > * {
			flex: 1;

			@media screen and (min-width: $md) {
				margin-top: 0;
			}
		}

		&-actions {
			display: flex;
			gap: $spaceBase;
			.MuiButton-root {
				padding: 7px 15px;
			}
		}
	}
}
