@import 'common/styles/variables';

.Dashboard {
	display: flex;
	flex-wrap: wrap;
	gap: $spaceBase;
	margin-top: $spaceBase * 2;

	&-item {
		align-items: center;
		border-radius: $spaceBase * 0.5;
		border: 1px solid $gray4;
		box-shadow: 1px 1px 6px $gray5;
		display: block;
		display: flex;
		flex-direction: column;
		flex: 0 1 200px;
		gap: $spaceBase;
		height: 100%;
		padding: $spaceBase;
		position: relative;
		text-align: center;
		transition: 175ms ease all;
		width: 100%;

		&:hover {
			box-shadow: 2px 2px 10px $gray7;

			.MuiSvgIcon-root {
				font-size: $spaceBase * 4;
				fill: $mainColor;
			}
		}

		.MuiSvgIcon-root {
			font-size: $spaceBase * 4;
			fill: $gray7;
		}
	}
}

.WriterRanking, .WriterComments {
	margin-top: $spaceBase * 2;
	border-top: 1px solid $gray3;

	h2 {
		small {
			color: $gray6;
			font-size: 16px;
		}
	}
}

.WriterRanking {
	&-wrapper {
		display: flex;
		gap: $spaceBase;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	&-rank, &-average {
		max-width: 100%;
	}
}

.WriterComments {
	&-comments {
		width: 416px;
		max-width: 100%;
	}
}
