@import 'common/styles/variables';

.Layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&-header {
		padding: $spaceBase/2 0;
		background: $mainColor;
		color: white;
	}

	&-body {
		flex: 1;

		&-container {
			padding: $spaceBase 0;
		}
	}

	&-footer {
		background: $gray2;
		padding: $spaceBase 0;
	}
}
