@import 'common/styles/variables';

.AssignmentFilters {
	margin-bottom: $spaceBase;

	.AssignmentFilterForm {
		align-items: flex-start;
		flex-direction: column;
		gap: $spaceBase;
		display: flex;

		@media screen and (min-width: $md) {
			flex-direction: row;
		}
	}
}
