@import 'common/styles/variables';

.PaymentMethodDialog .PaymentMethodForm {
	&-form {
		&-rates {
			margin-top: $spaceBase;

			&-item {
				margin-bottom: $spaceBase / 2;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
