@import 'common/styles/variables';

.PageTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: $spaceBase * 1.25;
	gap: $spaceBase;
	flex-wrap: wrap;

	&-actions {
		display: flex;
		align-items: center;
		gap: $spaceBase / 2;
	}
}
