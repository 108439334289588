// Grey scale
$white: #fff;
$gray1: #fafafa;
$gray2: #f5f5f5;
$gray3: #f0f0f0;
$gray4: #d9d9d9;
$gray5: #bfbfbf;
$gray6: #8c8c8c;
$gray7: #595959;
$gray8: #434343;
$gray9: #262626;
$gray10: #1f1f1f;
$gray11: #141414;
$black: #000;

// Theme colors
$mainColor: #005d90;
$secondaryColor: #ff6500;
$secondaryColorDark: darken($secondaryColor, 10%);
$textColor: #55585d;
$secondaryTextColor: lighten($textColor, 10%);

// Breakpoints
$xs: 448px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

// spacing
$spaceBase: 16px;
$radiusBase: $spaceBase / 2;
