@import 'common/styles/variables';

.RankingFilters {
	margin-bottom: $spaceBase;
	
	&-form {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: $spaceBase;
		flex-wrap: wrap;

		& > * {
			flex: 1 1 150px;
		}

		&-actions {
			display: flex;
			justify-content: flex-start;
			gap: $spaceBase;
		}
	}
}
